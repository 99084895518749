import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8066ee92"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "awesome-icon"
}
const _hoisted_2 = { class: "text" }
const _hoisted_3 = {
  key: 1,
  class: "right"
}
const _hoisted_4 = { class: "uppercase" }
const _hoisted_5 = {
  key: 2,
  class: "right"
}
const _hoisted_6 = { class: "uppercase" }
const _hoisted_7 = { class: "info-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_Timer = _resolveComponent("Timer")!
  const _component_markdown_render = _resolveComponent("markdown-render")!
  const _component_participant_tutorial = _resolveComponent("participant-tutorial")!
  const _component_ParticipantFinishedModuleComponent = _resolveComponent("ParticipantFinishedModuleComponent")!
  const _component_ParticipantModuleComponent = _resolveComponent("ParticipantModuleComponent")!
  const _component_ParticipantDefaultContainer = _resolveComponent("ParticipantDefaultContainer")!

  return (_openBlock(), _createBlock(_component_ParticipantDefaultContainer, {
    key: _ctx.componentLoadIndex,
    useFullSize: _ctx.useFullSize,
    backgroundClass: _ctx.backgroundClass,
    drawNavigation: _ctx.drawNavigation
  }, {
    header: _withCtx(() => [
      _createVNode(_component_el_page_header, {
        content: _ctx.taskType,
        title: _ctx.$t('general.back'),
        onBack: _ctx.back
      }, null, 8, ["content", "title", "onBack"]),
      (_ctx.task && _ctx.modules.length > 1)
        ? (_openBlock(), _createBlock(_component_el_tabs, {
            key: 0,
            stretch: false,
            modelValue: _ctx.moduleName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.moduleName) = $event)),
            class: "white",
            onTabClick: _cache[1] || (_cache[1] = (tab) => _ctx.moduleNameClick(tab.paneName))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modules, (module) => {
                return (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: module.name,
                  name: module.name
                }, {
                  label: _withCtx(() => [
                    (module.icon)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: module.icon
                          }, null, 8, ["icon"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(`module.${_ctx.taskType}.${module.name}.description.title`)), 1)
                  ]),
                  _: 2
                }, 1032, ["name"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.task && _ctx.timeLeft)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('participant.organism.modelDefaultContainer.timeLeft')), 1),
            _createVNode(_component_Timer, {
              class: "timer",
              "auth-header-typ": _ctx.EndpointAuthorisationType.PARTICIPANT,
              entity: _ctx.task,
              onTimerEnds: _ctx.goToOverview
            }, null, 8, ["auth-header-typ", "entity", "onTimerEnds"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.points && _ctx.showCoins)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_font_awesome_icon, { icon: "coins" })
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.points), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (
        _ctx.moduleDisplayState === _ctx.ModuleDisplayState.tutorial && _ctx.task?.description
      )
        ? (_openBlock(), _createBlock(_component_participant_tutorial, {
            key: 0,
            "module-info-entry-data-list": ['description'],
            onInfoRead: _cache[2] || (_cache[2] = ($event: any) => (_ctx.moduleDisplayState = _ctx.ModuleDisplayState.content)),
            "info-type": _ctx.moduleId,
            showTutorialOnlyOnce: _ctx.module.parameter.showTutorialOnlyOnce
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h1", null, _toDisplayString(_ctx.task.name), 1),
                _createElementVNode("p", null, [
                  _createVNode(_component_markdown_render, {
                    class: "markdown",
                    source: _ctx.task.description
                  }, null, 8, ["source"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["info-type", "showTutorialOnlyOnce"]))
        : (
        _ctx.task &&
        _ctx.state &&
        _ctx.state.state === _ctx.TaskParticipantStatesType.FINISHED &&
        !_ctx.task.parameter.displayFinished
      )
          ? (_openBlock(), _createBlock(_component_ParticipantFinishedModuleComponent, {
              key: 1,
              "task-id": _ctx.taskId,
              "module-id": _ctx.moduleId,
              useFullSize: _ctx.useFullSize,
              "onUpdate:useFullSize": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.useFullSize) = $event)),
              backgroundClass: _ctx.backgroundClass,
              "onUpdate:backgroundClass": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.backgroundClass) = $event))
            }, null, 8, ["task-id", "module-id", "useFullSize", "backgroundClass"]))
          : (_ctx.task)
            ? (_openBlock(), _createBlock(_component_ParticipantModuleComponent, {
                key: 2,
                "task-id": _ctx.taskId,
                "module-id": _ctx.moduleId,
                useFullSize: _ctx.useFullSize,
                "onUpdate:useFullSize": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.useFullSize) = $event)),
                backgroundClass: _ctx.backgroundClass,
                "onUpdate:backgroundClass": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.backgroundClass) = $event))
              }, null, 8, ["task-id", "module-id", "useFullSize", "backgroundClass"]))
            : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["useFullSize", "backgroundClass", "drawNavigation"]))
}